import type { BrandTestimonialDefinition } from "~/business-areas/brand/brand.model";

export const testimonials = {
  dorianaKoeberle: {
    id: "Doriana Koeberle",
    authorFullName: "Doriana Koeberle",
    authorType: "manager",
    authorCompanyName: "Bouygues",
    authorJob: "Responsable Marketing Stratégique",
    authorAvatar: "/images/brand/testimonials/dorianaKoeberle.jpg",
    authorCompanyLogo: "/images/brand/testimonials/bouygues_logo.svg",
    content:
      "Ma session Pollen m'a aidée à construire très concrètement mon plan d'actions. J'ai particulièrement apprécié les frameworks partagés et les ateliers pour repositionner les personas, très concret et activable.",
  },
  delphineMarsh: {
    id: "Delphine Marsh",
    authorFullName: "Delphine Marsh",
    authorType: "manager",
    authorCompanyName: "Akur8",
    authorJob: "Chief Talent Officer",
    authorAvatar: "/images/brand/testimonials/delphine_marsh.jpg",
    authorCompanyLogo: "/images/brand/testimonials/akur8_logo.svg",
    content:
      "Avec Pollen, nous avons enfin trouvé un partenaire formation adapté à nos besoins de start up dans un contexte international. Nos collaborateurs soulignent l'expertise des trainers, des sessions concrètes et pratiques, des échanges utiles avec des pairs.",
  },
  saskiaFiszel: {
    id: "Saskia Fiszel",
    authorFullName: "Saskia Fiszel",
    authorType: "manager",
    authorCompanyName: "Virgil",
    authorJob: "Co-Founder",
    authorAvatar: "/images/brand/testimonials/saskia_fiszel.jpg",
    authorCompanyLogo: "/images/logos/black-and-white/virgil.svg",
    content:
      "Une des top talents de mon équipe est revenue absolument ravie de sa session : des partages hyper inspirants et actionnables, des rencontres intéressantes et une trainer très pédagogue, je recommande !",
  },
  ashokAzhagarasan: {
    id: "Ashok Azhagarasan",
    authorFullName: "Ashok Azhagarasan",
    authorType: "manager",
    authorCompanyName: "OSS Ventures",
    authorJob: "Head of Growth",
    authorAvatar: "/images/brand/testimonials/ashok_azhagarasan.jpg",
    authorCompanyLogo: "/images/logos/black-and-white/oss_ventures.svg",
    content:
      "Une mine d'or pour les startups ! Très inspirant d'avoir de vrais partages d'expérience, ça fait gagner beaucoup de temps. En plus du trainer, on apprend aussi beaucoup des échanges entre pairs qui sont hyper riches.",
  },
  romainLibeau: {
    id: "Romain Libeau",
    authorFullName: "Romain Libeau",
    authorType: "learner",
    authorCompanyName: "Swile",
    authorJob: "CPO",
    authorAvatar: "/images/brand/testimonials/romain_libeau.jpg",
    authorCompanyLogo: "/images/logos/black-and-white/swile.svg",
    content:
      "De vrais opérationnels experts sur de vrais enjeux à cracker : enfin des formations concrètes, utiles et actionnables...en plus dans un cadre et une atmosphère très sympa !",
  },
  raphaelAlarcon: {
    id: "Raphaël Alarcon",
    authorFullName: "Raphaël Alarcon",
    authorType: "learner",
    authorCompanyName: "Welcome to the Jungle",
    authorJob: "Product Marketing Lead",
    authorAvatar: "/images/brand/testimonials/raphaelAlarcon.jpeg",
    authorCompanyLogo: "/images/brand/testimonials/wttj_logo.svg",
    content:
      "J’ai beaucoup aimé le format dense sur une matinée, facile à caler dans un agenda. Je suis reparti avec des frameworks super activables et des cas concrets de pairs qui partagent leur expérience, très utile.",
  },
  // charlotteAllibert: {
  //   authorFullName: "Charlotte Allibert",
  //   authorType: "learner",
  //   authorCompanyName: "Librinova",
  //   authorJob: "Directrice générale",
  //   authorAvatar: "/images/brand/testimonials/charlotteAllibert.jpg",
  //   authorCompanyLogo: "/images/brand/testimonials/librinova_logo.png",
  //   content:
  //     "J’ai suivi une formation Pollen et j’ai été convaincue par le format, très concret et petit groupe. Je suis repartie avec un plan d’actions très clair et beaucoup d’idées très inspirantes pour mon entreprise. Je souhaite maintenant proposer des formations Pollen à mes équipes, je le vois comme un formidable levier de motivation pour mes collaborateurs.",
  // },
  // laureBricard: {
  //   id: "Laure Bricard",
  //   authorFullName: "Laure Bricard",
  //   authorType: "learner",
  //   authorCompanyName: "Alteia",
  //   authorJob: "Product Marketing Manager ",
  //   authorAvatar: "/images/brand/testimonials/laureBricard.jpg",
  //   authorCompanyLogo: "/images/brand/testimonials/alteia_logo.svg",
  //   content:
  //     "Grâce à l'expertise d'Élodie et à la richesse des échanges lors de cette séance j'ai pu revoir la structuration de mes tâches et j'ai découvert des méthodologies concrètes à appliquer au quotidien.",
  // },
  carolineRoullet: {
    id: "Caroline Roullet",
    authorFullName: "Caroline Roullet",
    authorType: "learner",
    authorCompanyName: "VivaTech",
    authorJob: "CMO",
    authorAvatar: "/images/brand/testimonials/caroline_roullet.jpg",
    authorCompanyLogo: "/images/logos/black-and-white/vivatech.svg",
    content:
      "Extrêmement satisfaite de ma session Pollen : une intervenante très expérimentée, beaucoup d'échanges très intéressants avec les autres participants, des frameworks facilement réutilisables. J'ai également particulièrement aimé le fait que ce soit en anglais.",
  },
  victoireRivaton: {
    id: "Victoire Rivaton",
    authorFullName: "Victoire Rivaton",
    authorType: "learner",
    authorCompanyName: "Malt",
    authorJob: "Director of Global Corporate Communications",
    authorAvatar: "/images/brand/testimonials/victoireRivaton.jpg",
    authorCompanyLogo: "/images/logos/black-and-white/malt.svg",
    content:
      "L'expérience Pollen n'est pas seulement un \"cours\" classique avec un expert, c'est aussi un échange de haut niveau avec des pairs avec qui échanger sur des problématiques qui vous sortent de votre zone de confort.",
  },
  octaveLapeyronie: {
    id: "Octave Lapeyronie",
    authorFullName: "Octave Lapeyronie",
    authorType: "manager",
    authorCompanyName: "Fabriq",
    authorJob: "Co-fondateur",
    authorAvatar: "/images/brand/testimonials/octave-lapeyronie.jpg",
    authorCompanyLogo: "/images/logos/black-and-white/fabriq.svg",
    content:
      "Travailler avec Pollen nous permet d'aider nos équipes à monter rapidement en compétences sur des sujets précis et couvre toutes les fonctions clés pour une entreprise de SaaS B2B comme la notre.",
  },
  alexisSpyratos: {
    id: "Alexis Spyratos",
    authorFullName: "Alexis Spyratos",
    authorType: "manager",
    authorCompanyName: "Refectory",
    authorJob: "CFO",
    authorAvatar: "/images/brand/testimonials/alexis_spyratos.jpg",
    authorCompanyLogo: "/images/logos/black-and-white/refectory.svg",
    content:
      "Ma collaboratrice a adoré l'expérience Pollen, tant sur le format que le contenu. Elle est repartie avec une boite à outils complète, qu'elle utilise énormément dans son quotidien.",
  },
  nathalieClement: {
    id: "Nathalie Clément",
    authorFullName: "Nathalie Clément",
    authorCompanyName: "L'Oréal",
    authorJob: "Global Digital & E-commerce Upskilling Director",
    authorAvatar: "/images/brand/testimonials/nathalie_clement.jpg",
    authorType: "manager",
    authorCompanyLogo: "/images/logos/black-and-white/loreal.svg",
    content:
      "J’ai déjà choisi Pollen pour 3 programmes pour l’accès à des intervenants de très haut niveau et la qualité de l’accompagnement, réellement adapté à nos enjeux.",
  },
} satisfies Record<string, BrandTestimonialDefinition>;
